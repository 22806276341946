import { useLanguage } from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import { loadStripe, type Stripe } from '@stripe/stripe-js'
import { useMemo } from 'react'
import { trpc } from '../client'
import { isProductionEnvironment } from './env-utils'

export function useStripePromise(): Promise<Stripe | null> {
  const language = useLanguage()
  return useMemo(() => initStripePromise(language), [language])
}

async function initStripePromise(language: Language) {
  const result = await trpc.paymentIntent.getStripePublicKey.query()
  const publicKey = result.publicKey
  return loadStripe(publicKey, { locale: language })
}

export function getStripePaymentIntentUrl(stripePaymentIntentId: string): string {
  return `${getStripeBaseUrl()}/payments/${stripePaymentIntentId}`
}

export function getStripeCustomerUrl(stripeCustomerIntentId: string): string {
  return `${getStripeBaseUrl()}/customers/${stripeCustomerIntentId}`
}

function getStripeBaseUrl() {
  return isProductionEnvironment() ? 'https://dashboard.stripe.com' : 'https://dashboard.stripe.com/test'
}

export function getGocardlessPaymentIntentUrl(id: string): string {
  return `${getGocardlessBaseUrl()}/payments/${id}`
}

export function getGocardlessCustomerUrl(id: string): string {
  return `${getGocardlessBaseUrl()}/customers/${id}`
}

function getGocardlessBaseUrl() {
  return isProductionEnvironment() ? 'https://manage.gocardless.com' : 'https://manage-sandbox.gocardless.com'
}
